<template>
  <div>
    <v-list-item
      :href="href"
      :rel="href && href !== '#' ? 'noopener' : undefined"
      :target="href && href !== '#' ? '_blank' : '_self'"
      :to="item.to"
      active-class="navBarActive"
      v-if="item.title !== 'Logout'"
      @click="navClick(item.title)"
    >
      <v-list-item-icon
        v-if="text"
        class="v-list-item__icon--text"
        v-text="computedText"
      />

      <v-list-item-icon v-else-if="item.icon">
        <v-icon v-text="item.icon" />
      </v-list-item-icon>

      <v-list-item-icon v-else-if="item.img">
        <v-img :src="item.img"></v-img>
      </v-list-item-icon>

      <v-list-item-content v-if="item.title || item.subtitle">
        <v-list-item-title v-text="item.title" />

        <v-list-item-subtitle v-text="item.subtitle" />
      </v-list-item-content>
    </v-list-item>

    <v-list-item @click="go()" v-if="item.title === 'Logout'">
      <v-list-item-icon
        v-if="text"
        class="v-list-item__icon--text"
        v-text="computedText"
      />

      <v-list-item-icon v-else-if="item.icon">
        <v-icon v-text="item.icon" />
      </v-list-item-icon>

      <v-list-item-icon v-else-if="item.img">
        <v-img :src="item.img"></v-img>
      </v-list-item-icon>

      <v-list-item-content v-if="item.title || item.subtitle">
        <v-list-item-title v-text="item.title" />

        <v-list-item-subtitle v-text="item.subtitle" />
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable";

export default {
  name: "Item",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        img: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    href() {
      return this.item.href;
      // return this.item.href || (!this.item.to ? '#' : undefined)
    },
  },
  created() {
    // console.log('123------------------------');
    // console.log(this.item);
    // if (this.item.img) {
    //   this.item.img = require(`@/assets/images/desks/${this.item.img}.png`);
    // }
    // console.log(this.item);
  },
  methods: {
    go() {
      if (this.item.title === "Logout") {
        this.$axios({
          method: "POST",
          url: "/m/logout",
        })
          .then((res) => {
            // localStorage.clear();
            // sessionStorage.clear();
            localStorage.removeItem("client");
            localStorage.removeItem("config");
            localStorage.removeItem("userEmail");
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("workingHourStartTime");
            localStorage.removeItem("workingHourEndTime");
            localStorage.removeItem("showDeskButton");
            localStorage.removeItem("showRoomButton");
            localStorage.removeItem("advanceBookingLimit");
            localStorage.removeItem("advanceRoomBookingLimit");
            this.$router.push({ name: "LoginEmail" });
          })
          .catch((err) => {
            console.log(err);
            // localStorage.clear();
            // sessionStorage.clear();
            localStorage.removeItem("client");
            localStorage.removeItem("config");
            localStorage.removeItem("userEmail");
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("workingHourStartTime");
            localStorage.removeItem("workingHourEndTime");
            localStorage.removeItem("showDeskButton");
            localStorage.removeItem("showRoomButton");
            localStorage.removeItem("advanceBookingLimit");
            localStorage.removeItem("advanceRoomBookingLimit");
            this.$router.push({ name: "LoginEmail" });
          });
      }
      console.log(this.item);
    },
    navClick(nav) {
      if (window.gtag) {
        window.gtag("event", "NavBar", {
          event_category: nav,
          event_label: nav,
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || "",
        });
      }
    },
  },
};
</script>

